import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BillingCyclesComponent } from './billing-cycles.component';
import { Shell } from '@app/shell/shell.service';
import { AccessGuard } from '@app/@shared/guards/access.guard';
import { AccessType } from '@app/@shared/enums/access-types.enum';
import { CurrentMonthBillingCycleComponent, PreviousBillingCycleComponent } from '@app/areas/billing-cycles/components';
import { BillingCycleModuleRouteResolver, BillingCycleRouteResolver } from '@app/areas/billing-cycles/resolvers';
import { FA_ICON } from '@ep/shared';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':coalitionId/billing-cycles',
      component: BillingCyclesComponent,
      canActivate: [AccessGuard],
      data: { title: 'Billing Cycles', requiredAccessTypes: [AccessType.Billing] },
      resolve: { message: BillingCycleModuleRouteResolver },
    },
    {
      path: ':coalitionId/billing-cycles/current',
      component: CurrentMonthBillingCycleComponent,
      canActivate: [AccessGuard],
      data: { icon: FA_ICON.FILE_INVOICE_DOLLAR, title: 'Billing Cycle', requiredAccessTypes: [AccessType.Billing] },
      resolve: { data: BillingCycleModuleRouteResolver },
    },
    {
      path: ':coalitionId/billing-cycles/:billingCycleId',
      component: PreviousBillingCycleComponent,
      data: { icon: FA_ICON.FILE_INVOICE_DOLLAR, title: 'Billing Cycle' },
      resolve: { data: BillingCycleModuleRouteResolver, message: BillingCycleRouteResolver },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BillingCyclesRoutingModule {}
