import { Component, input } from '@angular/core';
import { CardModule } from 'primeng/card';
import { NgIf } from '@angular/common';
import { BillingCycle } from '@app/resources/services';
import { BillingCycleMerchantInvoicesTreeComponent } from '@app/areas/billing-cycles/components/billing-cycle-merchant-invoices-tree/billing-cycle-merchant-invoices-tree.component';

@Component({
  selector: 'app-billing-cycle-display-card',
  standalone: true,
  imports: [CardModule, NgIf, BillingCycleMerchantInvoicesTreeComponent],
  templateUrl: './billing-cycle-display-card.component.html',
  styleUrl: './billing-cycle-display-card.component.scss',
})
export class BillingCycleDisplayCardComponent {
  billingCycle = input<BillingCycle | null>();
}
