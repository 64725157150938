<p-card *ngIf="billingCycle(); let billingCycle">
  <ng-template pTemplate="header">
    <div id="billing-cycle-display-card-header">
      <h1>Billing Cycle {{ billingCycle.Name }}</h1>
    </div>
  </ng-template>
  <div>
    <app-billing-cycle-merchant-invoices-tree
      [merchantInvoicesInput]="billingCycle.Invoices"
    ></app-billing-cycle-merchant-invoices-tree>
  </div>
</p-card>
