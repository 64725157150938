import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { RouterActions } from '@app/resources/ngrx/actions';
import { ActivatedRoute, Router } from '@angular/router';
import { withLatestFrom } from 'rxjs';
import { selectRouter } from '@app/resources/ngrx/selectors';

@Injectable()
export class RouterEffects {
  private readonly action$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  navigateByUrl$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(RouterActions.navigateByUrl),
        withLatestFrom(this.store.select(selectRouter)),
        tap(([{ payload }, { state }]) => {
          this.router.navigate([`${payload.path}`], {
            ...payload.extras,
            relativeTo: this.route,
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  navigateByUrlWithCoalition$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(RouterActions.navigateByUrlWithCoalition),
        withLatestFrom(this.store.select(selectRouter)),
        tap(([{ payload }, { state }]) => {
          const coalitionId = state.params['coalitionId'];
          this.router.navigate([`${coalitionId}/${payload.path}`], {
            ...payload.extras,
            relativeTo: this.route,
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  navigateWithQueryParams$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(RouterActions.navigateWithQueryParams),
        tap(({ payload }) => {
          const path = !!payload.route ? [payload.route] : [];
          this.router.navigate(path, {
            relativeTo: this.route,
            queryParams: payload.queryParams,
            queryParamsHandling: 'merge',
          });
        })
      ),
    {
      dispatch: false,
    }
  );
}
