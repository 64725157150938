import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { RootState } from '@app/resources/ngrx/states';
import {
  _applicationProfileReducer,
  _authenticationReducer,
  _billingCycleReducer,
  _credentialsReducer,
  hydrationMetaReducer,
} from '@app/resources/ngrx/reducers';
import { routerReducer } from '@ngrx/router-store';

export const reducers: ActionReducerMap<RootState> = {
  applicationProfile: _applicationProfileReducer,
  authentication: _authenticationReducer,
  billingCycle: _billingCycleReducer,
  credentials: _credentialsReducer,
  router: routerReducer,
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];
