import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BillingCyclesRoutingModule } from './billing-cycles-routing.module';
import { BillingCyclesComponent } from './billing-cycles.component';
import { BillingCyclesApiService } from '@app/areas/billing-cycles/services';
import { BillingCycleModuleRouteResolver, BillingCycleRouteResolver } from '@app/areas/billing-cycles/resolvers';
import { EffectsModule } from '@ngrx/effects';
import { BillingCycleModuleEffects } from '@app/areas/billing-cycles/store';

@NgModule({
  imports: [
    CommonModule,
    BillingCyclesComponent,
    BillingCyclesRoutingModule,
    EffectsModule.forFeature([BillingCycleModuleEffects]),
  ],
  providers: [BillingCyclesApiService, BillingCycleModuleRouteResolver, BillingCycleRouteResolver],
})
export class BillingCyclesModule {}
