import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConfirmResetPasswordComponent } from './confirm-reset-password/confirm-reset-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: 'auth/login', component: LoginComponent, data: { title: 'Enroll And Pay' } },
  { path: 'auth/reset-password', component: ResetPasswordComponent, data: { title: 'Enroll And Pay' } },
  {
    path: 'auth/confirm-reset-password/:passwordResetId',
    component: ConfirmResetPasswordComponent,
    data: { title: 'Enroll And Pay' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
