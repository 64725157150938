import { RouterReducerState } from '@ngrx/router-store';
import {
  ApplicationProfileState,
  AuthenticationState,
  BillingCycleState,
  CredentialsState,
  RouterStateUrl,
} from '@app/resources/ngrx/states';

const DEFAULT_ENTITY = {
  ids: [],
  entities: {},
};

export interface RootState {
  applicationProfile: ApplicationProfileState;
  authentication: AuthenticationState;
  billingCycle: BillingCycleState;
  credentials: CredentialsState;
  router: RouterReducerState<RouterStateUrl>;
}

export const DEFAULT_ROOT_STATE: RootState = {
  applicationProfile: {},
  authentication: { loggedIn: false, showTermsAndConditions: false, error: null },
  billingCycle: {
    ...DEFAULT_ENTITY,
    selectedPastBillingCycleId: 0,
    table: {
      first: 0,
      rows: 10,
    },
  },
  credentials: { clientAccessId: null, jwt: null },
  router: {
    state: {
      url: '',
      queryParams: {},
      params: {},
    },
    navigationId: 0,
  },
};
