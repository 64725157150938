import { Component } from '@angular/core';
import { BillingCyclesTableComponent } from '@app/areas/billing-cycles/components/billing-cycles-table/billing-cycles-table.component';
import { CurrentMonthBillingCycleComponent } from '@app/areas/billing-cycles/components/current-month-billing-cycle/current-month-billing-cycle.component';

@Component({
  selector: 'app-billing-cycle-display-page',
  standalone: true,
  imports: [CurrentMonthBillingCycleComponent, BillingCyclesTableComponent],
  templateUrl: './billing-cycle-display-page.component.html',
  styleUrl: './billing-cycle-display-page.component.scss',
})
export class BillingCycleDisplayPageComponent {}
