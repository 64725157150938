import {
  BillingCycle,
  BillingCycleResponse,
  Invoice,
  InvoiceItem,
  InvoiceItemResponse,
  InvoiceResponse,
} from '@app/resources/services';
import { TreeNode } from 'primeng/api';
import { Merchant } from '@shared/models';

/*export const mapBillingCycleStatusToValue = (invoice: BillingCycle) => {
  switch (invoice.StatusType) {
    case BillingCycleStatusTypes.Active:
      return 'Active';
    case BillingCycleStatusTypes.Paid:
      return 'Paid';
    case BillingCycleStatusTypes.BillingCycled:
    default:
      return 'Unpaid';
  }
};

export const getBillingCycleStatusSeverity = (invoice: BillingCycle) => {
  switch (invoice.StatusType) {
    case BillingCycleStatusTypes.Active:
      return 'info';
    case BillingCycleStatusTypes.Paid:
      return 'success';
    case BillingCycleStatusTypes.BillingCycled:
    default:
      return 'warning';
  }
};*/

export const mapToInvoiceItem = (response: InvoiceItemResponse): InvoiceItem => {
  return {
    InvoiceItemId: response.invoiceItemId,
    InvoiceId: response.invoiceId,
    Name: response.name,
    Quantity: response.quantity,
    BilledAmount: response.billedAmount,
    ItemType: response.itemType,
  };
};

export const mapToInvoice = (response: InvoiceResponse): Invoice => {
  return {
    InvoiceId: response.invoiceId,
    Name: response.name,
    SmsCost: response.smsCost,
    StatusType: response.statusType,
    MerchantName: response.merchantName,
    AmountDue: response.amountDue,
    Items: response.items.$values.map(mapToInvoiceItem),
  };
};

export const mapToBillingCycle = (response: BillingCycleResponse): BillingCycle => {
  return {
    BillingCycleId: response.billingCycleId,
    Name: response.name,
    Invoices: response.invoices.$values.map(mapToInvoice),
  };
};

function isInstanceOfTreeNode(data: any): data is TreeNode[] {
  return data.length != 0 && ('data' in data[0] || 'children' in data[0]);
}

function mapInvoiceToTreeNode(data: Invoice): TreeNode {
  return {
    data: {
      name: data.MerchantName,
      quantity: '1',
      billedAmount: `${data.AmountDue}`,
    },
    children: data.Items.map((item) => {
      return {
        data: {
          name: item.Name,
          quantity: item.Quantity,
          billedAmount: item.BilledAmount,
        },
      };
    }),
  };
}

export const mapToMerchantInvoiceTreeNode = (data: TreeNode[] | Invoice[]): TreeNode[] => {
  if (isInstanceOfTreeNode(data)) return data;

  return data.map(mapInvoiceToTreeNode);
};
