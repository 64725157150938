import { inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, Observable, of, take, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { BillingCyclesApiService } from '@app/areas/billing-cycles/services';
import { BillingCycle } from '@app/resources/services';
import { Store } from '@ngrx/store';
import { selectPastBillingCycle } from '@app/resources/ngrx/selectors';
import { BillingCycleActions, MessagingActions, RouterActions } from '@app/resources/ngrx/actions';
import { isNotNullOrUndefined } from '@ep/shared';

@Injectable()
export class BillingCycleRouteResolver implements Resolve<any> {
  private readonly billingCycleApiService = inject(BillingCyclesApiService);
  private readonly store = inject(Store);

  resolve(route: ActivatedRouteSnapshot): Observable<BillingCycle> {
    let billingCycleId = parseInt(route.paramMap.get('billingCycleId') ?? '');
    if (isNaN(billingCycleId)) this.navigateToNotFound();

    return this.store.select(selectPastBillingCycle).pipe(
      filter(isNotNullOrUndefined),
      take(1),
      switchMap((billingCycle) => {
        if (billingCycle?.BillingCycleId != billingCycleId) {
          return this.billingCycleApiService.getBillingCycleById(billingCycleId).pipe(
            tap((billingCycle) => {
              if (!billingCycle) {
                this.navigateToNotFound();
              } else {
                this.store.dispatch(BillingCycleActions.upsertPastBillingCycleSuccess({ payload: { billingCycle } }));
                this.store.dispatch(BillingCycleActions.pastBillingCycleById({ payload: { billingCycleId } }));
              }
            }),
            catchError((err) => {
              const message = {
                severity: 'error',
                summary: `ERROR: Retrieve Billing Cycle`,
                detail: `Error getting past billing cycle information. Try again later.`,
              };
              this.store.dispatch(MessagingActions.createMessage({ payload: { message } }));
              return of(err);
            })
          );
        }
        this.store.dispatch(BillingCycleActions.pastBillingCycleById({ payload: { billingCycleId } }));
        return of(billingCycle);
      })
    );
  }

  private navigateToNotFound() {
    this.store.dispatch(RouterActions.navigateByUrl({ payload: { path: 'not-found' } }));
  }
}
