import { Component, effect, EffectRef, input, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { Invoice } from '@app/resources/services';
import { CurrencyPipe, NgClass, NgIf } from '@angular/common';
import { TreeTable, TreeTableModule } from 'primeng/treetable';
import { TreeNode } from 'primeng/api';
import { mapToMerchantInvoiceTreeNode } from '@app/areas/billing-cycles/utils';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, Subscription, tap } from 'rxjs';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-billing-cycle-merchant-invoices-tree',
  standalone: true,
  imports: [NgIf, TreeTableModule, CurrencyPipe, NgClass, InputTextModule, ReactiveFormsModule],
  templateUrl: './billing-cycle-merchant-invoices-tree.component.html',
  styleUrl: './billing-cycle-merchant-invoices-tree.component.scss',
})
export class BillingCycleMerchantInvoicesTreeComponent implements OnInit, OnDestroy {
  merchantInvoicesInput = input<Invoice[]>([]);
  merchantInvoiceTreeNodes = signal<TreeNode[]>([]);

  private merchantInvoiceEffect: EffectRef;
  protected globalSearchControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  private subs = new Subscription();

  constructor() {
    this.merchantInvoiceEffect = effect(
      () => {
        this.merchantInvoiceTreeNodes.set(mapToMerchantInvoiceTreeNode(this.merchantInvoicesInput()));
      },
      { allowSignalWrites: true, manualCleanup: true }
    );
  }

  ngOnInit() {
    this.subs.add(
      this.globalSearchControl.valueChanges
        .pipe(
          debounceTime(100),
          tap((searchText) => {
            const invoices = mapToMerchantInvoiceTreeNode(this.merchantInvoicesInput());
            this.merchantInvoiceTreeNodes.set(
              invoices.filter((invoice) => this.cleanText(invoice.data.name).includes(this.cleanText(searchText)))
            );
          })
        )
        .subscribe()
    );
  }

  private cleanText(text: string) {
    return text.trim().replace(' ', '').toLowerCase();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.destroyEffect();
  }

  destroyEffect() {
    this.merchantInvoiceEffect.destroy();
  }
}
