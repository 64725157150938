import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationProfile } from '../models/application-profile.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationProfileService {
  private applicationProfileSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  applicationProfile$: Observable<ApplicationProfile> = this.applicationProfileSubject.asObservable();

  constructor() {}

  setApplicationProfile(applicationProfile?: ApplicationProfile | null): void {
    this.applicationProfileSubject.next(applicationProfile ?? null);
  }

  getApplicationProfile(): ApplicationProfile {
    return this.applicationProfileSubject.value;
  }
}
