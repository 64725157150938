import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { isNotNullOrUndefined } from '@ep/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export enum BREADCRUMB_HOME_TYPES {
  ADMIN = 'Admin',
  BILLING = 'Billing Cycles',
}

const BREADCRUMB_HOME_OBJECTS: { [index: string]: MenuItem } = {
  [BREADCRUMB_HOME_TYPES.ADMIN]: {
    label: BREADCRUMB_HOME_TYPES.ADMIN,
  },
  [BREADCRUMB_HOME_TYPES.BILLING]: {
    label: BREADCRUMB_HOME_TYPES.BILLING,
  },
};

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  static readonly ROUTE_DATA_ICON = 'icon';
  static readonly BREADCRUMB_HOME_ICON = 'pi pi-home';
  static readonly DEFAULT_BREADCRUMB_HOME = { icon: BreadcrumbService.BREADCRUMB_HOME_ICON };

  private _breadcrumbs$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  private _home$: BehaviorSubject<MenuItem> = new BehaviorSubject<MenuItem>({ icon: 'pi pi-home' });

  readonly breadcrumbs$: Observable<MenuItem[]> = this._breadcrumbs$.asObservable();
  readonly home$: Observable<MenuItem> = this._home$.asObservable().pipe(filter(isNotNullOrUndefined));

  readonly shouldShowBreadcrumbs$ = this.breadcrumbs$.pipe(
    map((breadcrumbs) => {
      return (
        (this.router.url.includes('admin') || this.router.url.includes('billing-cycles')) && breadcrumbs.length > 0
      );
    })
  );

  setBreadcrumbs(breadcrumbs: MenuItem[]) {
    this._breadcrumbs$.next(breadcrumbs);
  }

  setHomeLabelAndUrl(data: MenuItem) {
    this._home$.next({
      icon: BreadcrumbService.BREADCRUMB_HOME_ICON,
      ...data,
    });
  }

  updateBreadcrumbs(coalitionId: number) {
    if (this.router.url.includes('admin'))
      this.setHomeLabelAndUrl({
        ...BREADCRUMB_HOME_OBJECTS[BREADCRUMB_HOME_TYPES.ADMIN],
        command: () => {
          this.router.navigate([`${coalitionId}/admin`]);
        },
      });
    else if (this.router.url.includes('billing-cycles')) {
      this.setHomeLabelAndUrl({
        ...BREADCRUMB_HOME_OBJECTS[BREADCRUMB_HOME_TYPES.BILLING],
        command: () => {
          this.router.navigate([`${coalitionId}/billing-cycles`]);
        },
      });
    } else this.setHomeLabelAndUrl(BreadcrumbService.DEFAULT_BREADCRUMB_HOME);

    const breadcrumbs = this.createBreadcrumbs(this.route);
    this.setBreadcrumbs(breadcrumbs);
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[BreadcrumbService.ROUTE_DATA_BREADCRUMB];
      const icon = child.snapshot.data[BreadcrumbService.ROUTE_DATA_ICON];
      if (isNotNullOrUndefined(label)) {
        breadcrumbs.push({
          label,
          command: () => {
            // Shell routing
            this.router.navigate([url]);
          },
          icon,
        });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}
