import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BillingCycle } from '@app/resources/services';

enum BillingCycleActionTypes {
  LOAD_PAST_BILLING_CYCLES = 'Load Past BillingCycles',
  LOAD_PAST_BILLING_CYCLES_SUCCESS = 'Load Past BillingCycles Success',
  LOAD_PAST_BILLING_CYCLES_ERROR = 'Load Past BillingCycles Error',
  UPSERT_PAST_BILLING_CYCLE_SUCCESS = 'Upsert Past BillingCycle Success',
  SELECT_PAST_BILLING_CYCLE = 'Past BillingCycle By Id',
  UPDATE_BILLING_CYCLE_TABLE_PAGE = 'Update Billing Cycle Table Page',
}

export const BillingCycleActions = createActionGroup({
  source: 'BillingCycle',
  events: {
    [BillingCycleActionTypes.LOAD_PAST_BILLING_CYCLES]: emptyProps(),
    [BillingCycleActionTypes.LOAD_PAST_BILLING_CYCLES_SUCCESS]: props<{ payload: { billingCycles: BillingCycle[] } }>(),
    [BillingCycleActionTypes.LOAD_PAST_BILLING_CYCLES_ERROR]: props<{ payload: { error: string } }>(),
    [BillingCycleActionTypes.UPSERT_PAST_BILLING_CYCLE_SUCCESS]: props<{ payload: { billingCycle: BillingCycle } }>(),
    [BillingCycleActionTypes.SELECT_PAST_BILLING_CYCLE]: props<{ payload: { billingCycleId: number } }>(),
    [BillingCycleActionTypes.UPDATE_BILLING_CYCLE_TABLE_PAGE]: props<{ payload: { first: number; rows: number } }>(),
  },
});
