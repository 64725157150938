import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, of } from 'rxjs';
import { BillingCyclesApiService } from '@app/areas/billing-cycles/services';
import { MessagingActions } from '@app/resources/ngrx/actions';
import { BillingCycleDisplayCardComponent } from '@app/areas/billing-cycles/components/billing-cycle-display-card/billing-cycle-display-card.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-current-month-billing-cycle',
  standalone: true,
  imports: [BillingCycleDisplayCardComponent, AsyncPipe],
  templateUrl: './current-month-billing-cycle.component.html',
  styleUrl: './current-month-billing-cycle.component.scss',
})
export class CurrentMonthBillingCycleComponent {
  private readonly billingCycleApiService = inject(BillingCyclesApiService);
  private readonly store = inject(Store);

  protected readonly currentMonthBillingCycle$ = this.billingCycleApiService.getCurrentMonthBillingCycle().pipe(
    catchError((err) => {
      const message = {
        severity: 'error',
        summary: `ERROR: Retrieve Billing Cycle`,
        detail: `Error getting billing cycle information for this month. Try again later.`,
      };
      this.store.dispatch(MessagingActions.createMessage({ payload: { message } }));
      return of(err);
    })
  );
}
