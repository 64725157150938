import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { Observable } from 'rxjs';
import { InvoiceItemTypes, InvoiceStatusTypes } from '@shared/enums';

export interface InvoiceItem {
  InvoiceItemId: number;
  InvoiceId: number;
  Name: string;
  Quantity: number;
  BilledAmount: number;
  ItemType: InvoiceItemTypes;
}

export interface Invoice {
  InvoiceId: number;
  Name: string;
  SmsCost: number;
  AmountDue: number;
  MerchantName: string;
  StatusType: InvoiceStatusTypes;
  Items: InvoiceItem[];
}

export interface InvoiceItemResponse {
  invoiceItemId: number;
  invoiceId: number;
  name: string;
  quantity: number;
  billedAmount: number;
  itemType: InvoiceItemTypes;
}

export interface InvoiceResponse {
  invoiceId: number;
  name: string;
  smsCost: number;
  amountDue: number;
  merchantName: string;
  statusType: InvoiceStatusTypes;
  items: {
    $values: InvoiceItemResponse[];
  };
}

interface InvoicesResponse {
  $values: InvoiceResponse[];
}

export interface Invoice {
  InvoiceId: number;
  Name: string;
  SmsCost: number;
  AmountDue: number;
  StatusType: InvoiceStatusTypes;
  Items: InvoiceItem[];
}

export interface BillingCycle {
  BillingCycleId: number;
  Name: string;
  Invoices: Invoice[];
}

export interface BillingCycleResponse {
  billingCycleId: number;
  name: string;
  invoices: {
    $values: InvoiceResponse[];
  };
}

interface BillingCyclesResponse {
  $values: BillingCycleResponse[];
}

@Injectable({
  providedIn: 'root',
})
export class BillingCycleService extends BaseApiService {
  private readonly billingCycleEndpoint = '/v2/BillingCycles';

  findCurrent(): Observable<BillingCycleResponse> {
    return this.get(`${this.billingCycleEndpoint}/current`);
  }

  findAllPast(): Observable<BillingCyclesResponse> {
    return this.get(`${this.billingCycleEndpoint}/past`);
  }

  findById(billingCycleId: number): Observable<BillingCycleResponse> {
    return this.get(`${this.billingCycleEndpoint}/${billingCycleId}`);
  }
}
