import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, of, take, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingCycleActions, MessagingActions, RouterActions } from '@app/resources/ngrx/actions';
import { BillingCyclesApiService } from '@app/areas/billing-cycles/services';
import { BillingCycleModuleActions } from '@app/areas/billing-cycles/store';
import { Store } from '@ngrx/store';

@Injectable()
export class BillingCycleModuleEffects {
  readonly actions$ = inject(Actions);
  readonly store = inject(Store);
  private readonly billingCyclesApiService = inject(BillingCyclesApiService);

  loadPastBillingCycles = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BillingCycleActions.loadPastBillingCycles),
        mergeMap(() => {
          return this.billingCyclesApiService.getPastBillingCycles().pipe(
            take(1),
            map((billingCycles) => BillingCycleActions.loadPastBillingCyclesSuccess({ payload: { billingCycles } })),
            catchError((error: { message: string }) => {
              const message = {
                severity: 'error',
                summary: `ERROR: Retrieving Billing Cycles`,
                detail: `Failed to load billing cycles. Please try again later.`,
              };
              this.store.dispatch(MessagingActions.createMessage({ payload: { message } }));

              return of(BillingCycleActions.loadPastBillingCyclesError({ payload: { error: error.message } }));
            })
          );
        })
      ),
    { dispatch: true }
  );

  navigatePastBillingCycle = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BillingCycleModuleActions.navigatePastBillingCycle),
        tap(({ payload }) => {
          const path = `billing-cycles/${payload.billingCycleId}`;
          this.store.dispatch(RouterActions.navigateByUrlWithCoalition({ payload: { path } }));
        })
      ),
    { dispatch: false }
  );
}
