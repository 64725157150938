import { Component, inject } from '@angular/core';
import { BillingCycleDisplayCardComponent } from '@app/areas/billing-cycles/components';
import { AsyncPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { isNotNullOrUndefined } from '@ep/shared';
import { filter } from 'rxjs/operators';
import { selectPastBillingCycle } from '@app/resources/ngrx/selectors';

@Component({
  selector: 'app-previous-billing-cycle',
  standalone: true,
  imports: [BillingCycleDisplayCardComponent, AsyncPipe],
  templateUrl: './previous-billing-cycle.component.html',
  styleUrl: './previous-billing-cycle.component.scss',
})
export class PreviousBillingCycleComponent {
  private readonly store = inject(Store);

  protected readonly pastBillingCycle$ = this.store.select(selectPastBillingCycle).pipe(filter(isNotNullOrUndefined));
}
