import { Coalition } from './coalition.model';
import { Invoice } from './invoice.model';

export interface Billing {
  $id: string;
  BillingCycleId: number;
  Coalition: Coalition;
  CoalitionId: number;
  EndDate: Date;
  EntityState: number;
  Invoices: Invoice[];
  StartDate: Date;
  StatusType: number;
  TS: string;
}
