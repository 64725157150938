import { createActionGroup, props } from '@ngrx/store';

/*
 * BillingCycle Module Actions
 */
enum BillingCycleModuleActionTypes {
  NAVIGATE_PAST_BILLING_CYCLE = 'Navigate Past BillingCycle',
}

export const BillingCycleModuleActions = createActionGroup({
  source: 'BillingCycle Module',
  events: {
    [BillingCycleModuleActionTypes.NAVIGATE_PAST_BILLING_CYCLE]: props<{ payload: { billingCycleId: number } }>(),
  },
});
