import { inject, Injectable } from '@angular/core';
import { BillingCycle, BillingCycleService } from '@app/resources/services/billing-cycle.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { mapToBillingCycle } from '@app/areas/billing-cycles/utils';
import { isNotNullOrUndefined } from '@ep/shared';

@Injectable()
export class BillingCyclesApiService {
  private readonly billingCycleService = inject(BillingCycleService);

  getCurrentMonthBillingCycle(): Observable<BillingCycle> {
    return this.billingCycleService.findCurrent().pipe(filter(isNotNullOrUndefined), map(mapToBillingCycle));
  }

  getPastBillingCycles(): Observable<BillingCycle[]> {
    return this.billingCycleService.findAllPast().pipe(map((result) => result.$values.map(mapToBillingCycle)));
  }

  getBillingCycleById(billingCycleId: number): Observable<BillingCycle> {
    return this.billingCycleService.findById(billingCycleId).pipe(filter(isNotNullOrUndefined), map(mapToBillingCycle));
  }
}
