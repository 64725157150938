import { EntityState } from '@ngrx/entity';
import { BillingCycle } from '@app/resources/services';

export interface BillingCycleState extends EntityState<BillingCycle> {
  selectedPastBillingCycleId: number | null;
  table: {
    first: number;
    rows: number;
  };
}
