import { Employee } from './employee.model';
import { Representative } from './representative.model';

interface ApplicationProfileCoalitionResponse {
  $id: number;
  CoalitionId: number;
  Name: string;
  InternalPortalDomain: string;
  MerchantPortalDomain: string;
}

interface ApplicationProfileRepresentativeResponse {
  $id: number;
  RepresentativeId: number;
  CoalitionId: number;
  NameFirst: string;
  NameLast: string;
  EmailAddress: string;
  PhoneNumber: string;
  AccessType: number;
  StatusType: number;
  Coalition: ApplicationProfileCoalitionResponse;
}

export interface ApplicationProfile {
  Representative: ApplicationProfileRepresentativeResponse;
}
