import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BillingCycleDisplayPageComponent, BillingCyclesTableComponent } from '@app/areas/billing-cycles/components';
import { RouterOutlet } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-billing-cycles',
  template: '<app-billing-cycles-table></app-billing-cycles-table>',
  styleUrls: ['./billing-cycles.component.scss'],
  standalone: true,
  imports: [BillingCycleDisplayPageComponent, RouterOutlet, BillingCyclesTableComponent],
})
export class BillingCyclesComponent {}
