import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BillingCycleState } from '@app/resources/ngrx/states';
import { isNotNullOrUndefined } from '@ep/shared';
import { selectBillingCycleEntities } from '@app/resources/ngrx/adapters';

export const selectBillingCycleState = createFeatureSelector<BillingCycleState>('billingCycle');
export const getBillingCycleEntities = createSelector(selectBillingCycleState, selectBillingCycleEntities);
export const selectPastBillingCycles = createSelector(getBillingCycleEntities, (billingCycleEntities) => {
  return Object.values(billingCycleEntities)
    .filter(isNotNullOrUndefined)
    .sort((a, b) => b.BillingCycleId - a.BillingCycleId);
});

export const selectPastBillingCycleId = createSelector(selectBillingCycleState, (state) => {
  return state.selectedPastBillingCycleId;
});

export const selectPastBillingCycle = createSelector(
  getBillingCycleEntities,
  selectPastBillingCycleId,
  (billingCycleEntities, billingCycleId) => {
    return billingCycleEntities[billingCycleId ?? 0];
  }
);

export const selectBillingCycleTableFirst = createSelector(selectBillingCycleState, (state) => state.table.first);
export const selectBillingCycleTableRows = createSelector(selectBillingCycleState, (state) => state.table.rows);
