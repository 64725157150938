import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable, of, shareReplay } from 'rxjs';
import { Credentials } from '@shared/models/credentials.model';
import { filter } from 'rxjs/operators';
import { isNotNullOrUndefined } from '@ep/shared';

@Injectable({
  providedIn: 'root',
})
export class LegacyLoadedService {
  private _isLegacyLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly _authenticateLegacyIframe$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );

  readonly isLegacyLoaded$: Observable<boolean> = this._isLegacyLoaded$.asObservable().pipe(shareReplay(1));
  readonly authenticateLegacyIframe$ = this._authenticateLegacyIframe$
    .asObservable()
    .pipe(filter(isNotNullOrUndefined), distinctUntilChanged(), shareReplay(1));

  setLegacyLoadedStatus(isLoaded: boolean) {
    this._isLegacyLoaded$.next(isLoaded);
  }

  get isLegacyLoaded() {
    return this._isLegacyLoaded$.value;
  }

  public authenticateLegacy(coalitionPortal: string, userCredentials: Credentials | null) {
    this.setLegacyLoadedStatus(false);
    const credentials = userCredentials;
    const iframe = `${coalitionPortal}/#Impersonate/${credentials?.jwt}/${credentials?.clientAccessId}`;
    this._authenticateLegacyIframe$.next(iframe);
  }
}
