import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CredentialsService } from '../services/credentials.service';
import { AccessType } from '../enums/access-types.enum';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const requiredAccessTypes: AccessType[] = route.data?.['requiredAccessTypes'];

    const hasAccess: boolean = this.credentialsService.hasAccessAny(requiredAccessTypes);

    if (!hasAccess) {
      const coalitionId = route.paramMap.get('coalitionId');
      this.router.navigate([`${coalitionId}/dashboard`]);
      return false;
    }

    return true;
  }
}
