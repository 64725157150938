import { createReducer, on } from '@ngrx/store';
import { BillingCycleState } from '@app/resources/ngrx/states';
import { BillingCycleActions } from '@app/resources/ngrx/actions';
import { billingCycleAdapter } from '@app/resources/ngrx/adapters';

export const initialBillingCycleModuleState: BillingCycleState = billingCycleAdapter.getInitialState({
  selectedPastBillingCycleId: 0,
  selectedId: 0,
  table: {
    first: 0,
    rows: 10,
  },
});

export const _billingCycleReducer = createReducer(
  initialBillingCycleModuleState,
  on(BillingCycleActions.loadPastBillingCyclesSuccess, (state, { payload }) => {
    billingCycleAdapter.removeAll(state);
    return billingCycleAdapter.addMany(payload.billingCycles, state);
  }),
  on(BillingCycleActions.pastBillingCycleById, (state, { payload }) => {
    return Object.assign({
      ...state,
      selectedPastBillingCycleId: payload.billingCycleId,
    });
  }),
  on(BillingCycleActions.updateBillingCycleTablePage, (state, { payload }) => {
    return Object.assign({
      ...state,
      table: payload,
    });
  })
);
